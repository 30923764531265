import * as navigationPreload from "workbox-navigation-preload";
import { NetworkFirst, CacheFirst } from "workbox-strategies";
import { registerRoute, NavigationRoute, Route } from "workbox-routing";
import { precacheAndRoute } from "workbox-precaching";

navigationPreload.enable();

precacheAndRoute(self.__WB_MANIFEST);

const navigationRoute = new NavigationRoute(new NetworkFirst({
  cacheName: "rjs-navigations"
}));

const staticRoute = new Route(({ url }) => {
  return /chemistreak\.js$/i.test(url) || /\.(jpe?g|png|ico)$/i.test(url);
}, new CacheFirst({
  cacheName: "rjs-assets"
}));

registerRoute(navigationRoute);
registerRoute(staticRoute);
